<template>
  <section class="bg-f5">
    <article class="container">
      <main class="box-shadow-card" ref="list">
        <h3>{{ $t("AllCategories") }}</h3>
        <p
          class="text-sm mt-8"
          v-html="
            $t('submitProjectEntry').replace(
              'projectsubmit',
              'defi/projectsubmit'
            )
          "
        ></p>
        <ul class="tabs" v-if="projectTags && projectTags.length">
          <li
            v-for="item in projectTags"
            :key="item.ID"
            :class="{ active: sID == item.ID }"
            @click="getProjectsByTag(item.ID)"
          >
            {{ item.title }}
          </li>
        </ul>
        <CateList
          v-if="projectList && projectList.length"
          :list="projectList"
          from="defi/"
        />
        <p class="null" v-else>{{ $t("NoInformation") }}</p>
        <div class="load-all" v-if="projectList && projectList.length && isAll">
          <span>{{ $t("NoMore") }}</span>
        </div>
      </main>
    </article>
    <!-- go top -->
    <img
      class="goTop"
      src="@/assets/img/icon/goTop.png"
      width="40"
      v-show="isBack"
      @click="goTop"
    />
  </section>
</template>
<script>
import CateList from "@/components/CateList";
export default {
  components: { CateList },
  data() {
    return {
      sID: 0, //project selected ID
      projectTags: null,
      projectList: null,
      limit: 32,
      page: 0,
      sw: false,
      isAll: false,
      isBack: false,
    };
  },
  created() {
    this.getTags();
  },
  mounted() {
    window.onscroll = (e) => {
      this.getMore();
    };
  },
  methods: {
    goTop() {
      window.scrollTo(0, 0);
    },
    getTags() {
      this.$loading(1);
      this.axios.get(this.domain + "getDefiProjectTags").then((res) => {
        let tags = res?.data?.data?.tags;

        if (tags && tags.length) {
          this.projectTags = [
            {
              ID: 0,
              title: "All",
            },
            ...tags,
          ];
          this.getProjectsByTag();
        } else {
          this.$loading(0);
        }
      });
    },
    getProjectsByTag(ID = 0) {
      this.page = 0;
      this.sw = false;
      this.isAll = false;
      this.$loading(1);
      this.axios
        .get(
          this.domain +
            "getDefiProjectList?v=1.0&tagIDs=" +
            ID +
            "&limit=" +
            this.limit +
            "&page=" +
            this.page
        )
        .then((res) => {
          let list = res?.data?.data;
          this.projectList = list;
          this.sID = ID;
          if (list && list.length >= this.limit) {
            this.sw = true;
            this.isAll = false;
          } else {
            this.sw = false;
            this.isAll = true;
          }
          this.$loading(0);
        })
        .catch((err) => {
          console.log(err);
          this.$loading(0);
        });
    },
    getMore() {
      // 返回顶部按钮
      let scrollTop =
        document.documentElement.scrollTop ||
        window.pageYOffset ||
        document.body.scrollTop;

      // 返回顶部按钮
      if (scrollTop > window.innerHeight) {
        this.isBack = true;
      } else {
        this.isBack = false;
      }
      // 加载更多
      if (
        this.sw &&
        this.$refs.list.offsetHeight - scrollTop <= window.innerHeight
      ) {
        this.sw = false;
        this.page++;
        this.$loading(1);
        this.axios
          .get(
            this.domain +
              "getDefiProjectList?v=1.0&tagIDs=" +
              this.sID +
              "&limit=" +
              this.limit +
              "&page=" +
              this.page
          )
          .then((res) => {
            let list = res?.data?.data;
            this.projectList = [...this.projectList, ...list];
            this.$loading(0);
            // 数据更新完毕，将开关打开
            if (list && list.length >= this.limit) {
              this.sw = true;
              this.isAll = false;
            } else {
              this.sw = false;
              this.isAll = true;
            }
          })
          .catch((err) => {
            console.log(err);
            this.$loading(0);
          });
      }
    },
  },
};
</script>
<style scoped src="../../assets/css/event.css"></style>
<style scoped>
.container {
  padding-top: 32px;
}
</style>